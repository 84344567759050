import getters from "./getters";
import actions from "./actions";
import stateData from "./state";

export default {
  namespaced: true,
  state: { ...stateData },
  mutations: {
    // 存储基本信息
    setVerify(state, data = {}) {
      for (let key in data) {
        state[key] = data[key];
      }
    },
    setMobiles(state, list= []){
      state.mobiles = list
    },
    // 登录信息
    setLoginList(state, list = []) {
      state.loginList = list;
    },
    // 定位列表
    setMapList(state, list = []) {
      state.mapList = list;
    },
    // 定位列表页码
    setMapPage(state, page = {}) {
      state.page = page;
    },
    // 范围人数
    setRangeData(state, rangeData = {}){
      state.rangeData = rangeData;
    },
  },
  actions,
  getters
};
