import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setBehavior(state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    }
  },
  actions: {
    getBehavior({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setBehavior", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        },'BEHAVIOR')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setBehavior", {
            provider,
            result_detail:data['BEHAVIOR'].data.result_detail,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    probeList1: state => {
      let data = state.result_detail;
      let color = "rgba(255, 0, 8, 1)";
      return [{
          name: "探查结果编码",
          value: data.result_code=='1'?'逾期未还款':data.result_code=='2'?'正常履约':data.result_code=='3'?'逾期又还款':'无法确定',
          color
        },
        {
          name: "最大逾期金额",
          value: data.max_overdue_amt || '-',
          unit: "元",
          color
        },
        {
          name: "最长逾期天数",
          value: data.max_overdue_days || '-',
          unit: "天",
          color
        },
        {
          name: "最近逾期时间",
          value: data.latest_overdue_time || "-",
          color
        },
        {
          name: "最大履约金额",
          value: data.max_performance_amt || '-',
          unit: "元",
          color
        },
        {
          name: "最近履约时间",
          value: data.latest_performance_time || "-",
          color
        },
        {
          name: "履约笔数",
          value: data.count_performance || '-',
          unit: "笔",
          color
        },
        {
          name: "当前逾期机构数",
          value: data.currently_overdue || '-',
          unit: "个",
          color
        },
        {
          name: "当前履约机构数",
          value: data.currently_performance || '-',
          unit: "个",
          color
        },
        {
          name: "异常还款机构数",
          value: data.acc_exc || '-',
          unit: "个",
          color
        },
        {
          name: "睡眠机构数",
          value: data.acc_sleep || '-',
          unit: "个",
          color
        }
      ];
    }
  }
};
