import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setArchives (state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    }
  },
  actions: {
    getArchives ({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setArchives", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        },'ARCHIVES')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setArchives", {
            provider,
            result_detail: data['ARCHIVES'].data.result_detail,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    list1: state => {
      let data = state.result_detail
      return [{
        member_count: data.member_count || 0,
        order_count: data.order_count || 0,
        debt_amount: data.debt_amount || 0,
        current_org_count: data.current_org_count || 0,
        current_order_count: data.current_order_count || 0,
        current_order_amt: data.current_order_amt || 0,
        current_order_lend_amt: data.current_order_lend_amt || 0
      }
      ]
    },
    list2: state => {
      let arr = []
      if(state.result_detail && state.result_detail.debt_detail){
        let data= state.result_detail.debt_detail;
        data.map(it => {
          arr.push({
            endDay: it.endDay || '',
            billType: it.billType || '',
            endMoney: it.endMoney || 0,
            endFlag: it.endFlag=='N'?'否':'是'
          })
        })
      }
      return arr;
    },
    list3: state => {
      let arr = []
      if(state.result_detail && state.result_detail.totaldebt_detail){
        let data= state.result_detail.totaldebt_detail;
        data.map(it => {
          arr.push({
            totaldebt_date: it.totaldebt_date || 0,
            totaldebt_org_count: it.totaldebt_org_count || 0,
            totaldebt_order_count: it.totaldebt_order_count || 0,
            totaldebt_order_amt: it.totaldebt_order_amt,
            new_or_old: it.new_or_old=='N'?'否':'是',
            totaldebt_order_lend_amt: it.totaldebt_order_lend_amt || 0
          }
          )
        })
      }
      return arr;
    }
  }
};
