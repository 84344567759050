import Vue from "vue";
import Vuex from "vuex";

import userReport from "@/components/viewBar/store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    XINYOU_ADMIN_USER: {}
  },
  mutations: {
    setUser(state, user = {}) {
      state.XINYOU_ADMIN_USER = user;
    }
  },
  modules: {
    userReport
  }
});
