import axios from "axios";
import qs from "qs";
import { MessageBox, Message } from "element-ui";
import { base, baseUrl } from "./env";

const reportUrls = ["/operator", "/contracts", "/loginRecord"];

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

// axios请求拦截
axios.interceptors.request.use(
  requestConfig => {
    // 发送请求前添加在请求头部加token
    const token = localStorage.getItem("token");
    if (token) requestConfig.headers.Authorization = "Bearer " + token;
    return requestConfig;
  },
  err => Promise.reject(err)
);

// axios响应拦截
axios.interceptors.response.use(
  responseConfig => {
    let code = responseConfig.data.code;
    if (code === "00000") {
      // 请求成功
      return Promise.resolve(responseConfig.data);
    } else if (code === "19999") {
      // 登录过期
      MessageBox.alert("您的登录已过期，请重新登录！", "提示", {
        showCancelButton: false
      }).then(() => {
        // reject(res, data);
        localStorage.removeItem("token");
        // 跳转登录
        location.href = location.origin + "/login";
      });
    } else if (code === "99999") {
      let { url = "" } = responseConfig.config || {};
      if (reportUrls.includes(url.slice(url.lastIndexOf("/")))) {
        // 查看报告相关接口，不提示错误信息
        return Promise.reject(responseConfig.data);
      } else {
        Message.error(responseConfig.data.msg || "网络请求错误，请稍后再试！");
        return Promise.reject(responseConfig.data);
      }
    } else {
      return Promise.resolve(responseConfig.data);
    }
  },
  err => {
    let { url = "" } = err.response.config || {};
    if (!reportUrls.includes(url.slice(url.lastIndexOf("/")))) {
      // 非报告相关接口，提示错误信息
      Message.error(err.response.data.error || "网络请求错误");
    }
    return Promise.reject(err);
  }
);

// 获取身份id
function getId() {
  let storage = localStorage.getItem("xinyou_admin_user");
  let user = JSON.parse(storage) || {};
  let { id = null } = user;
  return id;
}

/**
 * get请求
 * @params => 请求参数
 * @url => 请求链接
 * @auth => 是否需要登录信息id
 */
function requestGet(data, isBase = true) {
  let { params = {}, url = "", auth = false } = data;
  let reUrl = isBase ? baseUrl + url : base + url;
  if (auth) {
    let id = getId();
    params.userId = id;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(reUrl, { params })
      .then(res => {
        return resolve(res.data);
      })
      .catch(err => {
        console.error(err);
        return reject(err);
      });
  });
}

/**
 * post请求  base => 是否是baseUrl
 * @data => 请求参数
 * @url => 请求链接
 * @auth => 是否需要登录信息id
 */
function requestPost(params, isBase = true) {
  let { data = {}, url = "", auth = false } = params;
  let reUrl = isBase ? baseUrl + url : base + url;
  if (auth) {
    let id = getId();
    data.userId = id;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(reUrl, qs.stringify(data))
      .then(res => {
        return resolve(res.data || res);
      })
      .catch(err => {
        console.error(err);
        return reject(err);
      });
  });
}

export default {
  requestGet,
  requestPost,
  getId
};
