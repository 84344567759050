import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
// 上面这个 css 有点大，已经在 index.html 中从 CDN 引入
import "./scss/common.scss";
import { Message } from "element-ui";
import VueClipboard from "vue-clipboard2";
// import 'iview/dist/styles/iview.css';    // 使用 CSS
import axios from "axios";

// 在vue项目中解决Chrome控制台non-passive event listener输出问题
import "default-passive-events";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.prototype.$axios = axios;

Vue.prototype.$notify = options => {
  let { type = "success", message = "", duration = 1000, offset } = options;
  Message({ type, message, duration, offset });
};
Vue.prototype.pro = process.env.VUE_APP_PRO && process.env.VUE_APP_PRO === 'true';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
